import styles from "./Trainer.module.css";
export default function Trainer() {
  return (
    <div className={styles.main}>
      <h1>Наши тренера </h1>
      <div id="larisa" className={styles.diana}>
        <img src="date/larisa.jpg" alt="" />

        <p>
          <b className={styles.name}>Инструктор Лариса</b> <br />
          <b>Инструктор,Пилатес реформер</b> <br />
          Обучение
          <ul>
            <li>Топографическая анатомия ("Anatomy Study", Россия)</li>
            <li>Оценка опорно-двигательного annapama («Анатомия», Россия)</li>
            <li>Работа с тазовой диафрагмой («Crowschool", Россия)</li>
            <li> Пилатес (Казахстан)</li>
            <li>
              Core, Силовая Аэробика, Степ-Аэробика («Fitnation",
              Россия/Казахстан)
            </li>
            <li>Здоровая осанка (Россия)</li>
          </ul>
          <b>21 год не прерывного опыта и преподования</b>
        </p>
      </div>

      <hr />
      <div id="raihan" className={styles.diana}>
        <img src="date/raihan.jpg" alt="" />

        <p>
          <b className={styles.name}>Инструктор Райхан</b> <br />
          Опыт 4 года <br /> Ведение групповых и индивидуальных занятий для
          женщин Восстановила себя после травм в профессиональном спорте. Я
          использую те методы и приемы, которые помогли мне самой! <br />
          «Пилатес призван вернуть Вам естественную гибкость и грацию, а также
          обеспечить навыками, которые непременно отразятся на вашей походке,
          осанке, на том, как Вы отдыхаете, и на том, как вы работаете.»
        </p>
      </div>
      <hr />
      <div id="diana" className={styles.diana}>
        <img src="date/diana.jpg" alt="" />

        <p>
          <b className={styles.name}> Инструктор Диана</b> <br />
          Это отличный тренер, опыт работы до 1 года. <br /> « Попробуйте одно
          занятие и вам не захочется их больше пропускать. Уйдут напряжение и
          боли в спине, осанка улучшится, а эстетические изменения в вашем теле
          вдохновят на дальнейшие достижения! /»
        </p>
      </div>
      <hr />
      <div id="janel" className={styles.diana}>
        <img src="date/janel.jpg" alt="" />

        <p>
          <b className={styles.name}>Жанель</b> <br />
          Опыт работы: 4 года <br />
          образование: медецинское <br />
          Обучение: курс Pilates Reformer (UK,Астана)
          <br />
          <b>Жанель также преподает на английском языке</b>
        </p>
      </div>
      <div id="ilzat" className={styles.diana}>
        <img src="date/ilzat.jpg" alt="" />

        <p>
          <b className={styles.name}>Ильзат</b> <br />
          <ul>
            <li>
              Выпускник Академии Русского балета имени А. Я. Вагановой г.
              Санкт-Петербург
            </li>
            <li>Выпускник школы фитнеса WORLD CLASS</li>
            <li>
              С 2017 года является сертифицированным тренером по Pilates
              Reformers ( LEVEL 2)
            </li>
            <li>
              Групповые программы ( силовые тренировки , stretching, cycle и
              body ballet), а также персональные тренировки
            </li>
            
          </ul>
          <br />"Пилатес на реформере - комплексный подход к укреплению тела ,
            улучшению здоровья и повышение качества жизни !"
        </p>
      </div>
    </div>
  );
}
