import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Target.module.css';
import AnimatedComponent from '../AnimatedComponent';

export default function Target({ children, src, alt, text, Right }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5 // Adjust this threshold as needed
  });

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={`${styles.target} ${inView ? styles['in-view'] : ''}`} ref={ref}>
      <div className={Right ? styles.Right : ''}>
        {!Right && <img className={imageLoaded ? "loaded" : ""} src={src} alt={alt} onLoad={handleImageLoad} />}
        <p className={`${styles.text} ${imageLoaded ? "loaded" : ""}`}>
          <p className={styles.name}>{children}</p>
          <br />
          {text}
        </p>
        {Right && <img className={imageLoaded ? "loaded" : ""} src={src} alt={alt} onLoad={handleImageLoad} />}
      </div>
    </div>
  );
}
