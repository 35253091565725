import { LuSofa } from "react-icons/lu";
import { IoFitness } from "react-icons/io5";
import { IoIosFitness } from "react-icons/io";
import { GiJumpingRope } from "react-icons/gi";
import { HashLink as Link } from "react-router-hash-link";
import AnimatedComponent from "../AnimatedComponent";
import styles from "./Home.module.css";
import Questions from "./Questions";
import Target from "./Target";

export default function Home() {
  return (
    <div className={styles.main}>
      <div className={styles.preview}>
        <AnimatedComponent  Right>
          <h1 className={styles.brend}>pilatesprokz</h1>
        </AnimatedComponent >
        <AnimatedComponent Right={false}>
          <h2 className={styles.text}>
            Гармония тела и духа в каждом движении
          </h2>
        </AnimatedComponent>
      </div>
      <div className={styles.About}>
        <AnimatedComponent Right>
          <h2>Мы на рынке уже 5 лет</h2>
        </AnimatedComponent>
        <AnimatedComponent Right={false}>
          <h2>
            Мы постоянно совершенствуемся и развиваем наши методики, чтобы
            обеспечить нашим клиентами наилучшие результаты.
          </h2>
        </AnimatedComponent>
        <AnimatedComponent Right>
          <h2>
            Мы предлагаем занятия как в группах, так и индивидуально, чтобы
            каждый мог выбрать оптимальный формат обучения.
          </h2>
        </AnimatedComponent>
        <AnimatedComponent Right={false}>
          <h2>
            У вас есть возможность пройти пробное занятие всего от 2000 тенге и
            убедиться в качестве наших услуг.
          </h2>
        </AnimatedComponent>
        <Link className={styles.NavLink} to="/about#1">
          Узнать подробнее о нас
        </Link>
      </div>

      <Target
        src={"date/back.JPG"}
        text={
          "Пилатес улучшает все элементы физического состояния: силу,стойкость, гибкость, проворство, скорость; обучает корректной активации мышечного корсета; вносит коррективы в осанку."
        }
      >
        Здоровая осанка
      </Target>
      <Target
        Right
        src={"date/balans.JPG"}
        text={
          "Пилатес является ключом к гармонии баланса и силы. Он укрепляет мышцы, улучшая их тонус и силу, в то же время обучая контролю и стабильности. Это помогает улучшить равновесие, координацию и общую силу тела. Пилатес делает вас сильнее не только физически, но и улучшает ваше общее состояние благодаря улучшенному балансу и силе."
        }
      >
        Баланс и сила
      </Target>
      <Target
        src={"date/fell.JPG"}
        text={
          "Пилатес не просто способствует улучшению физической формы, но и повышает качество самочувствия. Он помогает усилить осознание своего тела, повышает концентрацию и способствует снижению стресса. Пилатес может сделать вас более энергичными, бодрыми и полными жизненной силы. Это не просто тренировка для тела, это тренировка для ума и души. Пилатес помогает вам чувствовать себя лучше в своем теле и улучшает ваше общее самочувствие."
        }
      >
        Самочуствие
      </Target>
      <h2 className={styles.when}>наши тренера</h2>
      <div className={styles.trainer}>
        <Link to="/trainer#larisa">
          <img src="date/larisa.jpg" alt="" />
        </Link>
        <Link to="/trainer#raihan">
          <img src="date/raihan.jpg" alt="" />
        </Link>
        <Link to="/trainer#janel">
          <img src="date/janel.jpg" alt="" />
        </Link>
        <Link to="/trainer#diana">
          <img src="date/diana.jpg" alt="" />
        </Link>
        <Link to="/trainer#ilzat" hash="ilzat">
          <img src="date/ilzat.jpg" alt="" />
        </Link>
      </div>

      <h2 className={styles.when}>когда к нам обращаются?</h2>
      <div className={styles.visit}>
        <AnimatedComponent>

        <div>
          <LuSofa className={styles.icon} />
          <p>
            <b>Малоподвижный образ жизни</b> <br /> Болевые ощущения в спине,
            шее, ногах, скованность движений
          </p>
        </div>
        </AnimatedComponent>
        <AnimatedComponent>
          
        <div>
          <IoIosFitness className={styles.icon} />
          <p>
            <b>Спортивное восстановление</b> <br /> Быстрое возвращение к форме,
            целенаправленная работа с поврежденными зонами
          </p>
        </div>
        </AnimatedComponent>
        <AnimatedComponent Right>

        <div>
          <IoFitness className={styles.icon} />
          <p>
            <b>Восстановительная терапия</b> <br />
            Для возвращения к нормальной жизни после переломов, вывихов,
            растяжений, при проблемах с подвижностью
          </p>
        </div>
        </AnimatedComponent>
        <AnimatedComponent Right>
          
        <div>
          <GiJumpingRope className={styles.icon} />
          <p>
            <b>Финтес</b> <br /> Персональные и групповые тренировки для
            подержания формы
          </p>
        </div>
        </AnimatedComponent>
      </div>
      <Questions
        text={
          "Основная цель пилатеса - помочь людям развивать сильное, сбалансированное тело через контролируемые, низкоинтенсивные движения, которые вовлекают ум и ставят вызов телу. Пилатес направлен на улучшение силы, стабильности и гибкости, выполняя повторяющиеся упражнения. Этот метод помогает улучшить осознание своего тела, поддерживая ежедневные движения, которые эффективны и грациозны. Пилатес также улучшает мышечный тонус, гибкость и силу, а также помогает восстановиться от травм."
        }
      >
        Для чего нужен пилатес ?
      </Questions>
      <Questions
        text={
          "Для занятий пилатесом на реформере вам потребуется облегающая одежда, которая позволит вам свободно двигаться. Материалы, которые отводят влагу, будут удерживать вас в сухости и комфорте во время тренировки. Верх может быть спортивным лифом или футболкой, а низ - леггинсами или спортивными шортами. Наконец, для пилатеса на реформере обычно требуются носки с нескользящим покрытием или специальная обувь для безопасности и гигиены. Главное, чтобы вы чувствовали себя комфортно во время занятий."
        }
      >
        Какая форма одежды?
      </Questions>
      <Questions
        text={
          "Пилатес подходит для всех, независимо от пола, возраста, расы, размера, способностей или текущего уровня физической подготовки. Это система упражнений, которая включает в себя более 600 упражнений и их вариаций. Пилатес помогает улучшить мышечный тонус, гибкость и силу, а также помогает восстановиться от травм"
        }
      >
        Для кого подходит пилатес?
      </Questions>
      <Questions
        text={
          "Пилатес может помочь вам похудеть, так же как и другие виды физической активности. Пилатес - это форма упражнений, которая улучшает силу, гибкость и осанку, а также помогает снизить уровень стресса. Как и любая физическая активность, пилатес помогает сжигать калории, что может привести к потере веса, особенно в сочетании с здоровым питанием и другими формами упражнений."
        }
      >
        Можно ли похудеть при помощи пилатеса ?
      </Questions>
      <Questions
        text={
          " Вы можете заниматься пилатесом один или два раза в неделю, чтобы дополнить другие упражнения и получить пользу от кросс-тренировки."
        }
      >
        Как часто нужно заниматься пилатесом, чтобы почувствовать эффект?
      </Questions>
    </div>
  );
}
