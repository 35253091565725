import { useState } from "react";
import styles from "./Questions.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export default function Questions({ children, text }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className={`${styles.questions} ${isExpanded ? styles.expanded : ''}`}
      >
        <h2>{children}</h2>
        {isExpanded ? <FaChevronUp className={`${styles.icon} ${styles.green}`} /> : <FaChevronDown className={styles.icon} />}
      </div>
      {isExpanded && <p className={styles.questions}>{text}</p>}
    </>
  );
}

