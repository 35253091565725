import { useInView } from 'react-intersection-observer';
import styles from './AnimatedComponent.module.css'

export default function AnimatedComponent({ children ,Right}) {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 1,
    });
  
    return <div ref={ref} className={inView ? Right ? styles.slideInRigth : styles.slideInLeft : styles.hidden}>{children}</div>;
}
  